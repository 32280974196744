.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.people {
  position: relative;
  margin: 50px 0px -64px calc((100% - 980px)*0.5);
  left: 100px;
  grid-area: 1/1/2/2;
  justify-self: start;
  align-self: start;
}

.connect {
  position: relative;
  margin: 0px 0px 45px calc((100% - 980px)*0.5);
  left: 600px;
  grid-area: 2/1/3/2;
  justify-self: start;
  align-self: start;
}

.people-connect-line {
  position: relative;
  margin: 18px 0px 10px calc((100% - 980px)*0.5);
  left:500px;
  grid-area: 2/1/3/2;
  justify-self: start;
  align-self: start;
}

